import {
  SegmentationDataResponse,
  fetchContentfulSegmentationData,
} from 'connectors/contentful';
import { SegmentationIds } from 'constants/segmentation';
import useSWR, { SWRConfiguration } from 'swr';

type ProductHandle = string;
type Segmentation = SegmentationIds;

type Response = {
  [key in ProductHandle]?: {
    [key in Segmentation]?: {
      usps: Document | undefined;
      saleCount: number | undefined;
      content: Document | undefined;
      isBestSeller: boolean;
    };
  };
};

type Input = {
  productHandles: Array<string>;
  swrOptions?: SWRConfiguration;
};

const useSegmentationData = ({ productHandles, swrOptions = {} }: Input) => {
  const { data, isValidating } = useSWR<Response | undefined>(
    ['useSegmentationData', ...productHandles],
    async (key, ...handles) => {
      if (handles.length === 0) {
        return Promise.resolve(undefined);
      }
      const segmentationData = await Promise.all(
        handles
          .map((handle) => `products/${handle}`)
          .map(fetchContentfulSegmentationData)
      );

      const filtered: Array<SegmentationDataResponse | undefined> =
        segmentationData.map((segByHandle) => segByHandle.items[0]);

      const res = productHandles.reduce((prev, cur, index) => {
        if (typeof filtered[index] === 'undefined') {
          return prev;
        }
        const segmentationDataEntries = (
          filtered[index] as SegmentationDataResponse
        ).fields.segmentationData.fields.segmentationDataEntries;
        const formattedSegmentationData = segmentationDataEntries?.reduce(
          (_prev, _cur) => {
            const key = _cur.fields.segment;
            _prev[key] = {
              ...(_cur.fields.usp && { usps: _cur.fields.usp }),
              ...(_cur.fields.salesCount && {
                saleCount: _cur.fields.salesCount,
              }),
              ...(_cur.fields.content && { content: _cur.fields.content }),
              isBestSeller: _cur.fields.isBestSeller,
            };
            return _prev;
          },
          {}
        );
        prev[cur] = formattedSegmentationData;
        return prev;
      }, {});

      return res;
    },
    swrOptions
  );

  return {
    data,
    isLoading: isValidating,
  };
};

export default useSegmentationData;
