export const slugify = (string: string) => {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Sanitize url for tracking purpose. Aimed for component tracking in which label should separated with "-".
 * First "/" is ignored to be replaced
 * @example
 * /collections/fur-hogehoge -> collections-fur-hogehoge
 */
export const sanitizeUrlForTracking = (url: string): string => {
  const firstIndex = url.indexOf('/'); // get the index of the first occurrence
  const pattern = /\//g; // create a regex pattern with the "g" flag to match all occurrences
  const sanitized = url.slice(firstIndex + 1, url.length).replace(pattern, '-'); // remove the first occurrence and replace the rest

  return sanitized;
};

/**
 * Convert string to camel case
 */
export const toCamelCase = (input: string): string => {
  const words = input.split(' ');
  const camelWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  return camelWords.join('');
};
