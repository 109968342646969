import { fetchProductDetails } from 'api/product';
import { CollectionProduct } from 'models/collection/collection';
import useSWR, { SWRConfiguration } from 'swr';
import { omitNullableHandler } from 'utils/type-helper';

type Input = {
  additionalProductHandles: Array<string>;
  swrOptions?: SWRConfiguration;
};

type Output = {
  data: Array<CollectionProduct> | undefined;
  isLoading: boolean;
};

const useAdditionalProducts = ({
  additionalProductHandles,
  swrOptions = {},
}: Input): Output => {
  const { data, isValidating } = useSWR<Array<CollectionProduct> | undefined>(
    [...additionalProductHandles],
    async (...arg) => {
      if (arg.length === 0) {
        return Promise.resolve(undefined);
      }
      const pDetails = await Promise.all(
        arg.map((handle) => fetchProductDetails(handle, undefined, true))
      );

      const filtered = pDetails
        .filter(omitNullableHandler)
        .map((p) => p.product)
        .map((p) => new CollectionProduct(p));

      return filtered;
    },
    swrOptions
  );

  return {
    data,
    isLoading: isValidating,
  };
};

export default useAdditionalProducts;
