import cn from 'classnames';
import { useTrackingContext } from 'contexts/tracking';
import { CollectionProduct } from 'models/collection/collection';
import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';
import { selectElement } from 'tracking';
import { Gtm, gtm } from 'tracking/gtm';
import Content from './content';

export const CARD_TYPES = {
  SLIDER: 'slider',
  SLIDER_LARGE: 'slider large',
  REGULAR: 'regular',
} as const;

export type CARD_TYPES = (typeof CARD_TYPES)[keyof typeof CARD_TYPES];

interface PropTypes {
  /** Collection Title for tracking */
  collectionTitle: string;
  personalizedRibbonText?: React.ReactNode;
  position: number;
  product: CollectionProduct;
  shouldShowBlackRibbon?: boolean;
  showSubscriptionPrice?: boolean;
  starSize?: 'small' | 'medium';
  trackingElementName?: string | string[];
  type?: CARD_TYPES;
  whiteBackground?: boolean;
  onTileClick?: () => void;
}

/**
 * Product Card. Wrapper for Product Card Content.
 * Includes tracking and navigation.
 */
const ProductCard: React.FC<PropTypes> = ({
  collectionTitle,
  personalizedRibbonText,
  position,
  product,
  shouldShowBlackRibbon,
  showSubscriptionPrice = false,
  starSize,
  trackingElementName,
  type = CARD_TYPES.REGULAR,
  whiteBackground,
  onTileClick,
}) => {
  const { id: productId, title: productTitle, handle } = product;
  const { setTrackedList } = useTrackingContext();

  const onGoToProduct = useCallback(() => {
    trackingElementName &&
      gtm(
        selectElement({
          element: [
            ...(Array.isArray(trackingElementName)
              ? trackingElementName
              : [trackingElementName]),
            productTitle,
          ],
        })
      );
    const { tags, productType } = product;
    gtm({
      group: Gtm.GroupName.Product,
      name: Gtm.ProductEventName.Click,
      data: {
        product: {
          productTitle,
          productId,
          productType,
          tags: tags.join(','),
          position,
          list: collectionTitle || '',
        },
      },
    });
    setTrackedList(+productId, collectionTitle || '');
  }, [
    productId,
    productTitle,
    collectionTitle,
    position,
    product,
    trackingElementName,
    setTrackedList,
  ]);

  const promotedVariant = useMemo(
    () =>
      product.variants.find(
        (variant) => variant.id === product.promoteVariantId
      ),
    [product]
  );

  const link = useMemo(
    () =>
      product.promoteVariantId && promotedVariant?.availableForSale
        ? `/products/${handle}?variant=${product.promoteVariantId}`
        : `/products/${handle}`,
    [handle, product.promoteVariantId, promotedVariant]
  );

  const content = (
    <Content
      personalizedRibbonText={personalizedRibbonText}
      product={product}
      shouldShowBlackRibbon={shouldShowBlackRibbon}
      showSubscriptionPrice={showSubscriptionPrice}
      starSize={starSize}
      type={type}
      whiteBackground={whiteBackground}
    />
  );

  return (
    <>
      {onTileClick ? (
        <button
          className="focus-default flex h-full w-full decoration-transparent"
          onClick={onTileClick}
        >
          {content}
        </button>
      ) : (
        <Link href={link} passHref>
          <a
            className={cn(
              'focus-default flex h-full w-full decoration-transparent'
            )}
            onClick={onGoToProduct}
          >
            {content}
          </a>
        </Link>
      )}
    </>
  );
};

export default ProductCard;
