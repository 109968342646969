import { useIsExistingCustomer } from 'hooks/common/use-is-existing-customer';
import { useCallback } from 'react';

// Product IDs of bundles to be hidden from new customers
const TARGET_PRODUCT_IDS = [
  7971498852618, 7971539190026, 7971540566282, 7971530375434, 7971520512266,
  7971489743114, 7971545940234, 7971502752010, 7971549151498, 7971493871882,
];
// Product IDs of bundles to be hidden from existing customers
const ACTUAL_PRODUCT_IDS = [
  4808737587234, 4808653897762, 4808653897762, 4808318648354, 4808328052770,
  4808754888738,
];

// test product for stating in order to test with EC and NC audieces
const TARGET_PRODUCT_IDS_STAGING = [4344673206314];

type ShouldHideFn = (productId: string | number) => boolean;

const useShouldProductBeHidden = (): ShouldHideFn => {
  // PT-3608 CDP::personalize bundles in CDP page for EC
  // see https://petsdeli.atlassian.net/browse/PT-3608
  const { isEc } = useIsExistingCustomer();

  const shouldHideFn: ShouldHideFn = useCallback(
    (productId) => {
      const _productId =
        typeof productId === 'string' ? Number(productId) : productId;

      // hide products for new customers
      if (!isEc) {
        return [...TARGET_PRODUCT_IDS, ...TARGET_PRODUCT_IDS_STAGING].includes(
          _productId
        );

        // hide products for existing customers
      } else {
        return [...ACTUAL_PRODUCT_IDS, ...TARGET_PRODUCT_IDS_STAGING].includes(
          _productId
        );
      }
    },
    [isEc]
  );

  return shouldHideFn;
};

export default useShouldProductBeHidden;
