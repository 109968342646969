import { getJudgeMeRatings } from 'api/judge-me';
import laggy from 'api/middleware/laggy';
import { CollectionResponse } from 'api/product';
import { useEffect, useMemo } from 'react';
import { ParsedReviews, useReviewsCacheStore } from 'stores/reviews-cache';
import useSWR from 'swr';
import { parseReviews } from 'utils/judge-me';

type Input = {
  collection?: CollectionResponse;
};

type Output = {
  data?: ParsedReviews;
  isLoading: boolean;
};

/**
 * Hook to fetch judgeMe review badge information of products in Collections
 */
const useJudgeMeReviews = ({ collection }: Input): Output => {
  const { setReviews } = useReviewsCacheStore();

  const productIds = useMemo(() => {
    if (!collection) return [];

    return collection.products.map((p) => p.id + '');
  }, [collection]);

  const { data, isValidating, mutate } = useSWR(
    collection ? `judge-me-${collection.handle}` : null,
    async () => {
      if (!productIds.length) return undefined;
      const rawReviews = await getJudgeMeRatings({ externalIds: productIds });
      if (!rawReviews) return undefined;
      return parseReviews({ rawReviews });
    },
    {
      use: [laggy],
      revalidateOnMount: false,
      onSuccess: (data) => {
        if (!data) return;
        setReviews((prev) => ({ ...prev, ...data }));
      },
    }
  );

  useEffect(() => {
    if (collection) {
      mutate();
    }
  }, [mutate, productIds, collection]);

  return {
    data,
    isLoading: isValidating,
  };
};

export default useJudgeMeReviews;
