export enum CollectionSorting {
  DEFAULT = 'COLLECTION_DEFAULT',
  TITLE = 'title',
  PRICE_ASCENDING = 'price-ascending',
  PRICE_DESCENDING = 'price-descending',
  NEW = 'new',
  BEST_SELLING = 'best-selling',
}

export const quickLinks = {
  [`${process.env.FOR_DOGS}`]: [
    {
      value: process.env.FOR_DOGS_WET_FOOD,
      label: process.env.WET_FOOD,
      src: 'PD-CDP-Quickmenu-Hund-NaFu.jpg',
    },
    {
      value: process.env.FOR_DOGS_BARF,
      label: process.env.BARF_2,
      src: 'PD-CDP-Quickmenu-Hund-BARF.jpg',
    },
    {
      value: process.env.FOR_DOGS_DRY_FOOD,
      label: process.env.DRY_FOOD,
      src: 'PD-CDP-Quickmenu-Hund-TroFu.jpg',
    },
    {
      value: process.env.FOR_DOGS_GREEN,
      label: process.env.GREEN,
      src: 'PD-CDP-Quickmenu-Hund-Green.jpg',
    },
    {
      value: process.env.FOR_DOGS_SUPPLEMENTS,
      label: process.env.SUPPLEMENTS,
      src: 'PD-CDP-Quickmenu-Hund-Supplementa.jpg',
    },
    {
      value: process.env.FOR_DOGS_HEALTY_2,
      label: process.env.HEALTH_2,
      src: 'PD-CDP-Quickmenu-Hund-Gesundheit.jpg',
    },
    {
      value: process.env.FOR_DOGS_BUNDLES,
      label: process.env.BUNDLES,
      src: 'PD-CDP-Quickmenu-Hund-Probierpakete.jpg',
    },
  ],
  [`${process.env.FOR_CATS}`]: [
    {
      value: process.env.FOR_CATS_WET_FOOD,
      label: process.env.WET_FOOD,
      src: 'PD-CDP-Quickmenu-Katze-NaFu.jpg',
    },
    {
      value: process.env.FOR_CATS_BARF,
      label: process.env.BARF_2,
      src: 'PD-CDP-Quickmenu-Katze-BARF.jpg',
    },
    {
      value: process.env.FOR_CATS_DRY_FOOD,
      label: process.env.DRY_FOOD,
      src: 'PD-CDP-Quickmenu-Katze-TroFu.jpg',
    },
    {
      value: process.env.FOR_CATS_SNACKS,
      label: process.env.SNACKS,
      src: 'PD-CDP-Quickmenu-Katze-Snacks.jpg',
    },
    {
      value: process.env.FOR_CATS_SUPPLEMENTS,
      label: process.env.SUPPLEMENTS,
      src: 'PD-CDP-Quickmenu-Katze-Supplementa.jpg',
    },
    {
      value: process.env.FOR_CATS_BUNDLES,
      label: process.env.BUNDLES,
      src: 'PD-CDP-Quickmenu-Katze-Probierpakete.jpg',
    },
  ],
};
