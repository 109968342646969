import { useProfile } from '@ninetailed/experience.js-next';
import { Product } from 'interfaces/product';
import { useCallback } from 'react';

type Input = Pick<Product, 'tags'>;
type Output = {
  shouldHideProduct: (arg: Input) => boolean;
};

const PT4187AudienceId = '2ryeCF6xJeY7EfJR3zhVMU';
const HIDE_CDP_TAG = 'hide_cdp';

/**
 * Hook to check if product should be hidden
 * @see : https://petsdeli.atlassian.net/browse/PT-4187
 */
const useTest4187 = (): Output => {
  const { profile } = useProfile();

  const shouldHideProduct = useCallback(
    ({ tags }: Input) => {
      if (tags.includes(HIDE_CDP_TAG)) {
        if (profile?.audiences.includes(PT4187AudienceId)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    [profile]
  );

  return {
    shouldHideProduct,
  };
};

export default useTest4187;
